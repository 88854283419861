import env from "../utils/env";

const constants = {
  ENV: env("NODE_ENV"),
  SENTRY: {
    ENABLE: env("SENTRY_ENABLE"),
    ENV: env("SENTRY_ENV"),
    INCLUDE: env("SENTRY_INCLUDE"),
    IGONRE_FILE: env("SENTRY_IGONRE_FILE"),
    IGONRE: env("SENTRY_IGONRE"),
    AUTHTOKEN: env("SENTRY_AUTHTOKEN"),
    ORG: env("SENTRY_ORG"),
    PROJECT: env("SENTRY_PROJECT"),
    CONFIG_FILE: env("SENTRY_CONFIG_FILE"),
    DSN: env("SENTRY_DSN"),
    TRACES_SAMPLE_RATE: env("SENTRY_TRACES_SAMPLE_RATE"),
  },
  API_URL: env("API_URL"),
  FEE_CALCULATOR_API_URL: env("FEE_CALCULATOR_API_URL"),
  ORS_HELP_FILES: env("ORS_HELP_FILES"),
  CONTEXT_TYPE: {
    USER: "user",
    TOKEN: "token",
    FILTER: "filter",
    IS_PENDING: "is_pending",
  },
  TOKEN_TYPE: {
    ACCESS: "access_token",
    REFRESH: "refresh_token",
  },
  DATE_TIME_FORMATS: {
    DATE_FLATPICKER: "d-m-Y",
    DATE_FLATPICKER_CERT: "d/m/Y",
    DATE_UI: "DD-MM-YYYY",
    DATE_API: "YYYY-MM-DD",
    DATE_FULL_MONTH: "DD-MMM-YYYY",
    DATE_DAY: "day",
    DATE_DAYS: "days",
    FULL_MONTH: "MMM YYYY",
    TIME_12_HR: "h:mma",
    TIME_24_HR: "HH:mm",
    FULL_TIME: "HH:mm:ss",
  },
  ACCESS: {
    CLIENT: "client",
    CLASSED: "classed",
    ISM_CLIENT: "isIsm",
    IHM_CLIENT: "isIhm",
    SSA_CLIENT: "isSsa",
    TAWA_CLIENT: "isTawa",
    WC_CLIENT: "isWc",
    RPS_CLIENT: "isRps",
    SRFA_CLIENT: "isSRFA",
    BSA_CLIENT: "isBsa",
    PERIODICAL_CLIENT: "isPeriodical",
    ADMIN: "admin",
    HOD: "hod",
    HOD_STAT: "hodStat",
    HOR: "hor",
    SIC: "sic",
    SIC_REG: "sicRegional",
    SURVEYOR: "surveyor",
    SS_REVIEWER: "ssReviewer",
    NR: "nr",
    ISM: "ism",
    ISM_STAFF: "ismStaff",
    ISPS: "isps",
    ISPS_STAFF: "ispsStaff",
    MLC: "mlc",
    MLC_STAFF: "mlcStaff",
    BNK: "bnk",
    BNK_STAFF: "bnkStaff",
    RPS: "rps",
    RPS_STAFF: "rpsStaff",
    PCS: "pcs",
    PCS_STAFF: "pcsStaff",
    TAWA: "tawa",
    TAWA_STAFF: "tawaStaff",
    ERS: "ers",
    ERS_IN: "ersIn",
    ERS_STAFF: "ersStaff",
    IHM: "ihm",
    RHOR: "RHOR",
    RHOD: "RHOD",
    SSA: "ssa",
    SSA_STAFF: "ssaStaff",
    HOO: "hoo",
    IA_QUALIFIED_AUDITOR: "ismispsmlcQualified",
    HODIT: "hodIT",
    HOSS: "hoss",
    PCS_QUALIFIED_AUDITOR: "pcsQualified",
    RPS_QUALIFIED_AUDITOR: "rpsQualified",
    BSA_QUALIFIED_AUDITOR: "bsaQualified",
    STAFF: "staff",
    FE_CLIENT: "isFE",
    FENC: "feNc",
    RADM: "RADM",
    DEFENCEHO: "defenceHo",
  },
  AUDIT_TYPE: {
    REMOTE_AUDIT: "Remote Audit",
    OFFLINE_AUDIT: "Offline Audit",
  },
  TOASTER_TYPES: {
    ERROR: "error",
    WARNING: "warn",
    SUCCESS: "success",
    LOG: "log",
  },
  DOT_TEXT: ".............",
  PAGE_CONTAINER_ID: "page-container",
  STATUS_FILTERS: {
    NOT_STATED: "notStarted",
    COMPLETED: "completed",
    IN_PROGRESS: "inProgress",
    ONE_DAY_LEFT: "oneDayLeft",
    OVERDUE: "overdue",
    ASSIGNED: "assigned",
    ASSIGNED_LABEL: "Assigned",
    NOT_ASSIGNED_LABEL: "Not Assigned",
    NOT_ASSIGNED: "not_assigned",
    REPORT_NO_GENERATED: "report_no_generated",
    REPORT_NO_NOT_GENERATED: "report_no_not_generated",
    REVIEW_PENDING: "review_pending",
    REVIEW_COMPLETE: "review_complete",
    PENDING_AMEND: "pending_amend",
    PENDING: {
      LABEL: "Pending",
      VALUE: "is_pending",
    },
    ISSUED: {
      LABEL: "Issued",
      VALUE: "is_issued",
    },
  },
  DEFAULT_TIME: "12:00 pm",
  QUICK_MENU_ID: "quick-menu",
  AUDIT_USER_TYPE: {
    ALL: "all",
    ME: "my-audits",
  },
  HOURS: {
    ZERO_HOUR: "00:00",
    EIGHT_HOUR: "08:00",
    SIXTEEN_HOUR: "16:00",
    ENDTIME: "23:59",
    START_TIME_LIMIT: "23:55",
    SINGLE_ZERO: "0:00",
    THWENTY_FOUR_HOUR: "24:00",
  },
  TIME_DIFF: {
    ZERO_HOURS: "00",
    ZERO_MINUTES: "00",
    FOURTY_EIGHT_HOURS: 48,
  },
  FILE_TYPE: {
    ZIP: "zip",
    CONTAINER: "container",
    OTHER_CONTAINER: "other-container",
  },
  REQUEST_TYPE: {
    WORK: "Works",
    WORK_TYPE: "WA",
    PRODUCT_TYPE: "TA",
    PRODUCT: "Product",
    WORK_APPROVAL: "wa",
    TYPES_APPROVAL: "ta",
    WORK_APPROVAL_TITLE: "Works Approval",
    TYPES_APPROVAL_TITLE: "Type Approval",
    RPS: "rps",
  },
  ACCORDION_MIN_ROWS: {
    LIST_LIMIT: 5,
  },
  TAWA_REQUEST_TYPE: {
    REQUEST_REVIEW: "REQUEST_REVIEW",
    PENDING_REVIEW: "Pending Reviews",
    PENDING_REPORT_REVIEW: "Pending Report Authorisation",
  },
  CONTRACT_PHASE: {
    CONTRACT_PHASE: "Contract Phase",
    CONTRACT_REVIEW: "Contract Review",
    SERVICE_REQUEST: "Service Request",
    ERS_AGREEMENT: "ERS Agreement",
    QOUTATION: "Quotation",
    SCRUTINY_DOCUMENT: "Scrutiny of Documents",
    TEST_PROTOCOL: "Test Protocol",
    APPROVAL_AUTH: "Auth Generation",
    SRF: "SRF",
    ROR: "ROR",
    COMMUNICATION: "Communication",
    SUPPORTING_DOCUMENT: "Supporting Document",
    OTHERS: "Others",
  },
  DEFICIENCY_TYPES: {
    PSC: "PSC",
    FSI: "FSI",
    PR17: "PR",
  },
  CRF_SUBTYPES: {
    INTERIM: "Interim",
    RENEWAL: "Renewal",
    ADDITIONAL: "Additional",
    INITIAL: "Initial",
    INTERMEDIATE: "Intermediate",
    ANNUAL: "Annual",
    VOLUNTARY: "Voluntary",
    PERIODIC: "Periodical",
    ADDITIONAL_FLAG: "Additional Flag",
  },
  YES: {
    LABEL: "Yes",
    VALUE: "yes",
  },
  NO: {
    LABEL: "No",
    VALUE: "no",
  },
  NA: {
    LABEL: "NA",
    VALUE: "na",
  },
  EXPLICIT: {
    LABEL: "Explicit",
    VALUE: "explicit",
  },
  TACIT: {
    LABEL: "Tacit",
    VALUE: "tacit",
  },
  OBSERVATIONS: {
    VALUE: {
      NEED_IMPROVEMENT: "need_improvement",
      SATISFACTORY: "satisfactory",
      ADEQUATE: "adequate",
      EFFECTIVE: "effective",
    },
    LABEL: {
      NEED_IMPROVEMENT: "Needs Improvement",
      SATISFACTORY: "Satisfactory",
      ADEQUATE: "Adequate",
      EFFECTIVE: "Effective",
    },
  },
  AUTHORIZE: "Authorize",
  APPROVE: "Approve",
  AUTH_LETTER_SECTION: {
    MAIN_SECTION: "Main Section",
    CC_SECTION: "CC Section",
    NOO_SECTION: "NOO Section",
  },
  AUTH_LETTER_TEMPLATE: {
    FLAG_OF_VESSELS: {
      FOREIGN: "Foreign",
    },
  },
  CONTRACT_REVIEW_STATUS: {
    ACCEPTED: "Accepted",
    AUTHORISED: "Authorised",
    REJECTED: "Rejected",
    CANCELLED: "Cancelled",
    PENDING: "Pending",
    LETTER_SENT: "Authorisation Sent",
    REVIEW_PENDING: "Review Pending",
    COMPLETED: "Completed",
    PENDING_DR: "Pending DR",
    DR_SUBMITTED: "DR Submitted",
    SAVED: "Draft",
    ASSIGNMENT_PENDING: {
      LABEL: "Assignment Pending",
      VALUE: "assignment_pending",
    },
    ON_HOLD: {
      LABEL: "On Hold",
      VALUE: "on_hold",
    },
    ON_HOLD_TAWA: {
      LABEL: "On Hold",
      VALUE: "Pending",
    },
    REVIEW_ON_PENDING: {
      LABEL: "Review Pending",
      VALUE: "Active",
    },
    LETTER_SENT_TAWA: {
      LABEL: "Authorisation Sent",
      VALUE: "authorised",
    },
    PROJECT_INITIATE_PENDING: {
      LABEL: "Project Initiation Pending",
      VALUE: "project_initiate_pending",
    },
    PROJECT_INITIATED: {
      LABEL: "Project Initiated",
      VALUE: "project_initiated",
    },
    OPEN: {
      LABEL: "Open",
      VALUE: "open",
    },
    TERMINATE: {
      LABEL: "Terminate",
      VALUE: "terminate",
    },
    PROVISIONAL_CERT_ISSUED: {
      LABEL: "Provisional Certificate Issued",
      VALUE: "is_provisional_cert",
    },
    AUTHORISATION_PENDING: "Authorisation Pending",
    DR_PENDING: { LABEL: "DR Pending", VALUE: "dr_pending" },
    DR_ASSIGNED: { LABEL: "DR Assigned", VALUE: "dr_assigned" },
    DR_COMPLETED: { LABEL: "DR Completed", VALUE: "dr_completed" },
    PENDING_ALLOCATION: {
      LABEL: "Pending Allocation",
      VALUE: "pending_allocation",
    },
    ALLOCATED: {
      LABEL: "Allocated",
      VALUE: "allocated",
    },
    SOFTWARE_APPROVED: {
      LABEL: "Software Approved",
      VALUE: "approved_request",
    },
    SOFTWARE_REJECTED: {
      LABEL: "Software Rejected",
      VALUE: "software_request_rejected",
    },
    PENDING_SOFTWARE_APPROVAL: {
      LABEL: "Pending For Approval",
      VALUE: "software_request",
    },
    REMOTE_AUDIT: {
      LABEL: "Remote",
      VALUE: "remote_request",
    },
    CONCURRENCE_PENDING: {
      LABEL: "Concurrence Pending",
      VALUE: "concurrence_pending",
    },
  },
  ERS_STATUS: {
    ACTIVE: { LABEL: "Active", VALUE: "is_active" },
    REQUESTED: { LABEL: "Requested", VALUE: "is_requested" },
    WITHDRAWN: { LABEL: "Withdrawn", VALUE: "is_withdrawn" },
    AGREEMENT_SENT: { LABEL: "Agreement Sent", VALUE: "agreement_sent" },
    AGREEMENT_PENDING: { LABEL: "Agreement Pending", VALUE: "agreement_pending" },
    AGREEMENT_PENDING_IRS: { LABEL: "Agreement Pending (with IRS)", VALUE: "agreement_pending_irs" },
    INTERIM_CERT_PENDING: { LABEL: "Interim Certificate Pending", VALUE: "interim_cert_pending" },
    INTERIM_CERT_ISSUED: { LABEL: "Interim Certificate Issued", VALUE: "interim_cert_issued" },
    FT_CERT_PENDING: { LABEL: "FT Certificate Pending", VALUE: "ft_cert_pending" },
    MODELS_PENDING: { LABEL: "Models Pending", VALUE: "models_pending" },
    SUBMITTED_FOR_REVIEW: { LABEL: "Submitted for Review", VALUE: "send_for_review" },
    CERT_EXPIRED: { LABEL: "Certificate Expired", VALUE: "is_expired" },
  },
  ESP_PLANNING_STATUS: {
    REVIEW_PENDING: "Pending",
    COMPLETED: "Completed",
  },
  PROJECT_STATUS: {
    ON_HOLD: {
      LABEL: "On Hold",
      VALUE: "Pending",
    },
    OPEN: {
      LABEL: "Open",
      VALUE: "Open",
    },
    TERMINATE: {
      LABEL: "Terminate",
      VALUE: "Terminate",
    },
    CLOSE: {
      LABEL: "Close",
      VALUE: "close",
    },
    SUSPENDED: {
      LABEL: "Suspend",
      VALUE: "Suspend",
    },
    REACTIVATED: {
      LABEL: "Reactivate",
      VALUE: "Reactivate",
    },
  },

  DATE_OF_AUDIT: {
    PLANNED: "Planned Date of Audit/Survey:",
    PROPOSED: "Proposed Date of Audit/Survey:",
  },
  REQUEST_DATE: "Date of Request",
  DATE_OF_AUDIT_PS: {
    PLANNED: "Planned Date of Survey:",
    PROPOSED: "Proposed Date of Survey:",
  },
  DATE_OF_AUDIT_RPS: {
    PLANNED: "Planned Date of Inspection:",
    PROPOSED: "Proposed Date of Inspection:",
  },
  NOTIFICATION_TYPE: {
    SUCCESS: "success",
    ERROR: "error",
    INFO: "info",
    WARNING: "warning",
    PROGRESS: "progress",
    CLOSE: "close",
    CONFIRM: "confirm",
  },
  COLOR: {
    PRIMARY_COLOR: "#00789A",
  },
  LIST_LIMIT: 50,
  LIST_LIMIT_MAX: 500,
  LIST_LIMIT_10: 10,
  DOC_TYPE: {
    REQUESTED: "Requested",
    UPLOADED: "Uploaded",
  },
  SHOW_FOOTER_SCROLL_POSITION: 100,
  OTHERS: "Others",
  REMOTE: "Remote",

  VALIDATION_TYPE: {
    GREATER_THAN: "Greater than",
  },
  ENGLISH: "English",
  ISM_CODE: "ISM Code",
  AUDIT_ROLES: {
    VALUE: {
      TL: "TL",
      TM: "TM",
      TA: "TA",
      MONITOR: "Monitor",
    },
    LABEL: {
      TL: "Team Leader",
      TM: "Team Member",
      TA: "Trainee",
      MONITOR: "Monitor",
    },
  },
  ACCESS_ROLES: {
    SIC: "sic",
    HO: "ho",
    HO_EXAMINER: "ho-examiner",
  },
  REMARKS_REASON_TYPE: {
    SURVEYOR_ACTION: "Surveyor Action",
  },
  CONTRACT_PHASES: {
    APPROVAL_AUTHORISATION_GENERATION: "AAG",
  },
  CHANGE_OF_FLAG: "Change of Flag",
  CHANGE_OF_MANAGEMENT_AND_FLAG: "Change of Management & Flag",
  SHIP_STATUS: {
    REINSTATED: "Reinstated",
    WITHDRAWN: "Withdrawn",
    CLASSED: "Classed",
    SUSPENDED: "Suspended",
    DELETED: "Deleted",
    LAID_UP: "Laid-up",
    REACTIVATED: "Reactivated",
    NON_CLASSED: "Non-Classed",
    LAID_UP_COLD: "Laid-up (Cold)",
    LAID_UP_HOT: "Laid-up (Hot)",
    RECLASSED: "Reclassed",
    SUSPENDED_AND_LAID_UP: "Suspended and Laid-up",
  },
  REASONS_TYPE: {
    CANCELLED: "Cancelled",
    AMEND: "Amend",
    REJECTED: "Rejected",
    ON_HOLD: "On-Hold",
    CERTIFICATE_WITHDRAWN: "Certificate withdrawn",
    APPROVED_FOR: "Approved For",
  },
  SCOPE: {
    INITIAL: "Initial",
    ANNUAL: "Annual",
    RENEWAL: "Renewal",
    AMENDMENT: "Amendment",
  },
  TYPES: {
    TYPES_APPROVAL: "TA",
    WORK_APPROVAL: "WA",
    TYPES_APPROVAL_LC: "ta",
    WORK_APPROVAL_LC: "wa",
    WELDING_CONSUMABLE: "wc",
  },
  BUTTON_TYPE: {
    TAWA_SAVE_BTN: "SAVE",
    TAWA_APPROVE_BTN: "APPROVE",
    TAWA_ONHOLD_BTN: "ONHOLD",
    TAWA_DECLINE_BTN: "DECLINE",
  },

  FILE_STATUS: {
    DELETED: "Deleted",
    UPLOADED: "Uploaded",
    REQUESTED: "Requested",
  },
  SLA_LIMIT: {
    ISM_ISPS_MLC: {
      OVERDUE: 7,
      REVIEWER: 3,
      SURVEYOR: 4, //Need to be change in future
      SIC: 1, //Need to be change in future
      CERTIFICATE: 15,
      HO_REVIEW: 5,
      MLC_CERTIFICATE: 21,
      MLC_HO_REVIEW: 11,
    },
    SSA: {
      REVIEWER: { INITIAL: 7, RETURNED: 3 },
      SURVEYOR: 3, //Need to be change in future
      SIC: 30, //Need to be change in future
      CERTIFICATE: 15,
      HOR: 7,
    },
    BSA: {
      HOR: 7,
      REVIEWER: { INITIAL: 7, RETURNED: 3, HO: 8 },
    },
    RPS: {
      HOR: 7,
      REVIEWER: { INITIAL: 7, RETURNED: 3, HO: 15 },
    },
    SRFA: {
      REVIEWER: 3,
      SURVEYOR: 4,
      SIC: 1,
      CERTIFICATE: 15,
      HO_REVIEW: 5,
      OVERDUE: 7,
    },
    PS: {
      TARGET_INDAYS: { SS_REPORTING: 30, SS_REVIEW: 30, FT_CERT_ISSUE: 45, HO_REVIEW: 10, RETURNED_BY_HO_AFTER_AUDIT: 10 },
      DUE_INDAYS: { SS_REPORTING: 6, SS_REVIEW: 6, FT_CERT_ISSUE: 6, HO_REVIEW: 4, RETURNED_BY_HO_AFTER_AUDIT: 4 },
      REVIEWER: 25,
      CERTIFICATE: 40,
      HO_REVIEW: 7,
    },
    OVERDUE: 0,
  },
  MINUTE_STEP: 5,
  VIRTUALIZED_ENABLE_LIMIT: 10,
  VIRTUALIZED_ENABLE_FULL_LIMIT: 100,
  VIRTUALIZED_ENABLE_LIMIT_FOR_MULTISELECT: 5,
  SPECIFY: "Specify",
  SPECIFY_SCOPE: "Specify Scope",
  PROVIDE_DETAILS: "Provide Details",
  MIN_SEARCH_LEN: 2,
  OVERDUE_LABEL: {
    DAYS_LEFT: "Days Left",
    OVERDUE: "Overdue",
    DUE: "Due",
  },
  SURVEY_TYPE_FILTER: {
    ID: {
      ISM_SMC: "ISM-SMC",
      ISM_SMC_VOLN: "ISM-SMC-VOLN",
      ISM_DOC: "ISM-DOC",
      ISM_DOC_VOLN: "ISM-DOC-VOLN",
      ISPS_INTL: "ISPS-INTL",
      ISPS_COASTAL: "ISPS-CSTL",
      ISPS_INTL_VOLN: "ISPS-VOLN",
      MLC: "MLC-MLC",
      MLC_VOLN: "MLC-VOLN",
      MLC_CSTL: "MLC-CSTL",
      DDOC: "DDOC",
      RPS: "RPS",
      SRFA: "SRFA",
    },
    VALUE: {
      ISM_SMC: 50,
      ISM_SMC_VOLN: 1231,
      ISM_EU_COASTAL: 171,
      ISM_DOC: 53,
      ISM_DOC_VOLN: 2628,
      ISPS_INTL: 699,
      ISPS_COASTAL: 2904,
      ISPS_INTL_VOLN: 2492,
      MLC: 2091,
      MLC_VOLN: 2906,
      MLC_CSTL: 2905,
      RPS: 2151,
      RPS_VOLN: 2628,
      BSA: 2452,
      SRFA: 3083,
      DDOC: 2471,
    },
    LABEL: {
      ISM_SMC: "ISM-SMC",
      ISM_SMC_VOLN: "ISM-SMC (Voluntary)",
      ISM_DOC: "ISM-DOC",
      ISM_DOC_VOLN: "ISM-DOC (Voluntary)",
      ISPS_INTL: "ISPS-International",
      ISPS_COASTAL: "ISPS-Coastal",
      ISPS_INTL_VOLN: "ISPS-International (Voluntary)",
      MLC: "MLC",
      MLC_VOLN: "MLC (Voluntary)",
      MLC_CSTL: "MLC-Coastal",
      RPS_VOLN: "RPS (Voluntary)",
      RPS: "RPS",
      BSA: "BSA",
      SRFA: "SRFA",
      DDOC: "DDOC",
    },
  },

  REPORT_TYPE_FILTER: {
    ID: {
      NEW_CONSTRUCTION: "New-Construction",
      EXISTING_SHIPS: "Existing-Ships",
      EX_STOCKS: "Ex-Stock",
      MULTIPLE_SHIPS: "Multiple-Ships",
      NAVY_COAST_GUARD_VESSELS: "Navy-Coast-Guard-Vessels",
    },
    VALUE: {
      NEW_CONSTRUCTION: 1,
      EXISTING_SHIPS: 2,
      EX_STOCKS: 3,
      MULTIPLE_SHIPS: 4,
      NAVY_COAST_GUARD_VESSELS: 5,
    },
    LABEL: {
      NEW_CONSTRUCTION: "New Construction",
      EXISTING_SHIPS: "Existing Ships",
      EX_STOCKS: "Ex-Stock",
      MULTIPLE_SHIPS: "Multiple Ships",
      NAVY_COAST_GUARD_VESSELS: "Navy/Coast Guard Vessels",
    },
  },

  DOWNLOAD_FILENAMES: {
    AUTH_LETTER: "Authorisation_Letter",
    CRF: "Contract_Review_Form",
  },
  TOTAL_HOURS: {
    INITIAL: 0,
    EIGHT_HOUR: 8,
    ELEVAN_HOUR: 11,
    SIXTEEN_HOUR: 16,
  },
  SCHEDULE_COUNT: {
    INITIAL: 0,
    INCREMENT_BY: 1,
    SCHEDULE_COUNT_THREE: 3,
    SCHEDULE_COUNT_TWO: 2,
  },
  SCHEDULE_BREAK: {
    LABEL: "Break",
    VALUE: "break",
  },
  CERTIFICATE_ISSUED_WITH: {
    ISM_CODE: "ISM-Code",
    EU_Coastal: "EU-Coastal",
    SOC: "SOC",
  },
  TIME_UNIT: {
    MINUTE: "minute",
    HOUR: "hour",
  },
  REQUEST_FORM_TYPES: {
    ISM_ISPS_MLC: "ism-isps-mlc",
    MLC_COASTAL: "mlc-coastal",
    TYPE_APPROVAL: "type-approval",
    WORK_APPROVAL: "work-approval",
    RPS: "rps",
    SSA: "ssa",
    BSA: "bsa",
    IHM: "ihm",
    SRFA: "srfa",
  },
  CLIENT_REQUEST_TYPE: {
    SHIP_AUDIT: "Ship",
    COMPANY_AUDIT: "Company",
    ISM_ISPS_MLC: "ism",
    MLC_COASTAL: "ism",
    TYPE_APPROVAL: "ta",
    WORK_APPROVAL: "wa",
    RPS: "rps",
    BSA: "bsa",
    SSA: "ssa",
    PERIODICAL_SURVEY: "periodical",
    SI: "si",
    SRFA: "srfa",
    IHM: "ihm",
    RFR: "rfr",
    WC: "wc",
    ERS: "ers",
    FE: "fe",
    ESP: "esp",
    MCS: "mcs",
  },

  VESSEL_TYPE: {
    CLASSED_VESSELS: "Classed",
    NON_CLASSED_VESSELS: "Non Classed",
  },

  SURVEY_REQUEST_FOR: {
    CHANGE_OF_FLAG: "changeOfFlag",
    CHANGE_OF_STATUTORY_CERTIFICATION_WITHOUT_COF: "changeOfStatWithoutFlag",
    PERIODICAL_SURVEY_REQUEST: "periodicalSurveyRequest",
  },

  DOCUMENT: {
    MOVING: "Moving",
    COPYING: "Copying",
    MOVED: "Moved",
    COPIED: "Copied",
    MOVE: "Move",
    COPY: "Copy",
  },
  CHANGE_OF_MANAGEMENT: "Change of Management",
  FORM_CODES: {
    PREMILINARY: "ZZ",
    CONTRACT_REVIEW: "CR1",
  },
  BSA_FORM_SHORT_CODES: {
    BSA_BUNKER_SUP_NY: "NY",
    BSA_CONTRACT_REVIEW: "CR1",
    BSA_MMSA_BUNKER_DETAILS: "10U",
    CONTRACT_REVIEW: "CR1",
    RPS_RPT_REVIEW_REM: "OP",
    BUNKER_SUP_OW: "OW",
    BSA_PROCESS_MONITORING: "APM",
    BSA_REQUEST_PROCESS_TIME: "ARP",
    BSA_SATISFACTORY_AUDITS: "BSA",
    BSA_ACTIVE_SUPPLIERS: "BAS",
    BUNKER_SUP_VO: "VO",
    BUNKER_SUP_NZ: "NZ",
  },
  LIST_LIMIT_20: 20,
  GRID_LIMIT: 20,
  SIC_OVERRIDE: 1,
  FOR_REQUEST_FORMS: 1,
  MIN_GT_VALUE: 500,
  LIST_LIMIT_50: 50,
  FILE_SIZE_LIMIT: 12,
  HO_FILE_SIZE_LIMIT: 30,
  SRF_DOCUMENT_ID: 166,
  TAWA_CLIENT: {
    CLIENT: "Client",
  },
  ONE_DAY_SCHEDULE_TIME_LIMIT: 1440,
  TIME_STEP_INCREMENT_DECREMENT_BY: 1,
  SHIP_REPORT_STATUS_TYPE: {
    SHIP_PARTICULAR: "ship-particular",
    SHIP_OWNER_INFO: "ship-owner",
    SHIP_CERTIFICATE: "ship-certificate",
    SHIP_SURVEY_STATUS: "survey-status",
    CONDITION_OF_CLASS: "condition-of-class",
    STATUTORY_RECOMMENDATION_INFO: "statutory-recommendation",
    MEMORANDA: "memoranda",
    ADDITIONAL_INFO: "additional-information",
    COMPLIENCE_REGULATIONS: "compliance-regulation",
    CONTINUOUS_SURVEY: "continuous-survey",
    SURVEYABLE_ITEMS: "surveyable-item",
    SURVEY_HISTORY: "survey-history",
  },
  CERTIFICATE_TYPE: {
    FULL_TERM: "Full Term",
    SHORT_TERM: "Short Term",
    CLASS: "Class",
    STATUTORY: "Statutory",
    INTERIM: "Interim",
    QA: "QA",
    QC: "QC",
    PROVISIONAL: "Provisional",
    ENDORSEMENT: "Endorsement",
    NO_ACTION_REQUIRED: "No Action",
    EXTEND: "Extended",
  },
  CERTIFICATE_ISSUANCE: {
    AMEND: "Amend",
    EXTEND: "Extend",
  },
  CERT_ISSUE_AUTHORITY: {
    IRS: "IRS",
  },
  SSA_SCOPE: {
    INITIAL: "Initial",
    RENEWAL: "Renewal",
    ADDITIONAL: "Additional",
  },
  DATE_FILTERS: {
    LABEL: {
      UPCOMING: "Upcoming",
      LAST_WEEK: "Last week",
      LAST_MONTH: "Last month",
      LAST_3_MONTH: "Last 3 months",
      ALL: "All",
    },
    VALUE: {
      UPCOMING: "upcoming",
      LAST_WEEK: "week",
      LAST_MONTH: "month",
      LAST_3_MONTH: "months3",
      ALL: "all",
    },
  },
  MAIL_DOMAINS: {
    IR_CLASS: "irclass.org",
  },
  SORT_DIRECTIONS: {
    ASC: "asc",
    DESC: "desc",
  },
  PAGE_VIEW_TYPE: {
    AMEND: "amend",
    UPDATE: "update",
  },
  FEEDBACK_TYPE: {
    ANALYSIS: "Analysis",
    FEEDBACK: "Feedback",
  },
  STAGES: {
    DR: "DR",
    OA: "OA",
    FR: "FR",
    DO: "DO",
  },
  RPS_SURVEY_CATEGORY_ID: 2151,
  Flag: {
    INDIAN: "indian",
  },
  OS_TYPES: {
    WINDOWS: "windows",
    MAC: "mac",
    LINUX: "linux",
  },
  SKELETON_LOADING_ROWS: {
    DEFAULT: 5,
    PAGING: 2,
  },
  NCS_FORM: {
    DOWNGRADED: "downgraded",
    MAJOR_NC: "major-non-conformity",
    NC: "non-conformity",
    DF: "deficiency",
    DOWNGRADE_TO: {
      50: "non-conformity",
      1231: "non-conformity",
      53: "non-conformity",
      2628: "non-conformity",
      699: "non-conformity",
      2904: "non-conformity",
      2492: "non-conformity",
      2091: "deficiency",
      2906: "deficiency",
      2905: "minor-deficiency",
    },
  },
  COUNTRY_CODE: {
    INDIA: "+91",
  },
  PCS_DOCUMENTS_CHECKLIST: {
    CONTRACT_REVIEW: "Contract Review",
  },
  RPS_AUDIT_TYPES: {
    LABEL: {
      INITIAL: "Initial",
      INTERMEDIATE: "Intermediate",
      ANNUAL: "Annual",
      RENEWAL: "Renewal",
      ADDITIONAL: "Additional",
      RE_INSPECTION: "Re-Inspection",
    },
    VALUE: {
      INITIAL: 521,
      INTERMEDIATE: 506,
      ANNUAL: 523,
      RENEWAL: 522,
      ADDITIONAL: 1047,
      RE_INSPECTION: 530,
    },
  },
  BSA_AUDIT_TYPES: {
    INITIAL: 485,
    INTERIM: 486,
    ANNUAL: 487,
    RENEWAL: 488,
    ADDITIONAL: 489,
  },
  COUNTRY_LIST: {
    INDIA: 1345,
  },
  VESSEL_GROUP_OTHERS_CODE: "OCS",
  COLORS: {
    DEFAULT: "default",
    PRIMARY: "primary",
    SECONDARY: "secondary",
    SUCCESS: "success",
    INFO: "info",
    WARNING: "warning",
    DANGER: "danger",
  },
  COUNTRY_FLAG_CODE: {
    PANAMA: "PAN",
    INDIA: "IND",
  },
  BSA_ADDITIONAL_AUDIT_ID: 489,
  BSA_DEFAULT_API_PARMAS: {
    SURVEY_TYPE: 2452,
    BSA_ADDITIONAL_AUDIT_ID: 489,
  },
  DIGITAL_SIGNATURE_MENU_AXIS: {
    X_AXIS: 4,
    Y_AXIS: 6.7,
  },
  BSA_DOCUMENTS_CHECKLIST: {
    DGS_DELIVERABLES: "Report Authorisation",
    CONTRACT_REVIEW: "Contract Review",
    SUPPORTING_DOCS: "Supporting Document",
  },
  SSA_DOCUMENTS_CHECKLIST: {
    CONTRACT_REVIEW: "Contract Review",
    SUPPORTING_DOCS: "Supporting Document",
  },
  SRFA_DOCUMENTS_CHECKLIST: {
    CONTRACT_REVIEW: "Contract Review",
  },
  ERS_DOCUMENTS_CHECKLIST: {
    ERS_AGREEMENT: "ERS Agreement",
    SUPPORTING_DOCUMENT: "Supporting Document",
    EPLAN_MODELS: "Eplan Models",
    ERS_SIGNED_AGREEMENT: "ERS Signed Agreement",
    FINAL_AGREEMENT: "Final Agreement",
    SERVICE_REQUEST: "Service Request",
    OTHERS: "Others",
  },
  MODULES: {
    ISM: "ism",
    RPS: "rps",
    PCS: "pcs",
    TAWA: "tawa",
    SSA: "ssa",
    BSA: "bsa",
  },
  LOCATION_TYPE: {
    ANCHORAGE: "Anchorage",
    PORT: "Port",
    DRY_DOCK: "Dry Dock",
    VOYAGE: "Voyage",
  },
  MIS_SURVEY_TYPE_ID: {
    SMC: 50,
    SMV: 1231,
    DOC: 53,
    ISP: 699,
    VCISP: 2492,
    ISPC: 2904,
    MLC: 2091,
    MLCC: 2905,
    MLCV: 2906,
  },
  MIS_SURVEY_TYPE_DATA: [
    { id: 0, survey_type_code: "Select All" },
    { id: 1, survey_type_code: "ISM" },
    { id: 2, survey_type_code: "ISPS" },
    { id: 3, survey_type_code: "MLC" },
  ],
  MIS_REPORT_TYPE_DATA: [
    { id: 1, name: "send_for_review_pending", type: "Reports Completed But Not Submitted To SIC" },
    { id: 2, name: "ss_review_pending", type: "Reports Pending At SS For Review" },
    { id: 3, name: "ho_review_pending", type: "Reports Pending At HO For Review" },
    { id: 3, name: "certificate_dispatch_pending", type: "Reports Pending For Dispatch" },
    { id: 5, name: "certificate_dispatched", type: "Reports Dispatched" },
    { id: 6, name: "ss_authorized", type: "Reports Authorized" },
    { id: 7, name: "auth_letter_sent", type: "Contract Review Details" },
    { id: 8, name: "contract_review_amends", type: "Modified Contract Review" },
  ],
  AUDIT_INSPECTION_REPORT_TYPE: [
    { id: 0, name: "Select All" },
    { id: 1, name: "FT Cert" },
    { id: 2, name: "FT Amendment" },
    { id: 3, name: "FT Endorsed" },
    { id: 4, name: "ST/INT Cert" },
    { id: 5, name: "ST/INT Cert Extend" },
    { id: 6, name: "FT Cert Extend" },
    { id: 7, name: "ST/INT Amend Cert" },
    { id: 8, name: "SOC Issue" },
    { id: 9, name: "SOC Amend" },
  ],
  INVOICE_DETAILS_REPORT_TYPE: [
    { id: 0, name: "Select All" },
    { id: 1, name: "First Entry (Existing)" },
    { id: 2, name: "First Entry (New Construction)" },
    { id: 3, name: "Ship Vetting" },
    { id: 4, name: "Component Surveys-Marine" },
    { id: 5, name: "Building Certificates" },
    { id: 6, name: "Material Tests" },
    { id: 7, name: "Miscellaneous/Coast Guard" },
    { id: 8, name: "Periodical Miscellaneous" },
    { id: 9, name: "Periodical" },
    { id: 10, name: "ISM Audit" },
    { id: 11, name: "ISPS Audit" },
    { id: 12, name: "MLC Inspection" },
    { id: 13, name: "RPS Reports" },
    { id: 14, name: "Bunker Suppliers" },
    { id: 15, name: "Service Suppliers" },
    { id: 16, name: "Single Voyage" },
    { id: 17, name: "Towing Permission" },
    { id: 18, name: "Type Approval" },
    { id: 19, name: "Work Approval" },
    { id: 20, name: "Non-Classed" },
  ],
  TAWA_SRF_SHORT_CODE: "16G",
  TAWA_CLIENT_COMUNICATIONS: "16F",
  TAWA_FAX_AUTH: "16E",
  TAWA_ROR_SHORT_CODE: "16RR",
  TAWA_2Y_SHORT_CODE: "2Y",
  TAWA_3R_SHORT_CODE: "3R",
  TAWA_3Q_SHORT_CODE: "3Q",
  TAWA_3P_SHORT_CODE: "3P",
  TAWA_3O_SHORT_CODE: "3O",
  TAWA_15R_SHORT_CODE: "15R",
  TAWA_DR_CHECKLIST_127: 127,
  TAWA_DR_CHECKLIST_5: 5,
  TAWA_DR_CHECKLIST_119: 119,
  TAWA_WORK_TYPE: {
    FEROSE_FOUNDERY: 67,
    FEROSE_AND_NON_FEROSE: 3204,
    NON_FEROSE_FOUNDERY: 2725,
  },
  TAWA_DOCUMENTS_CHECKLIST: {
    CERTIFICATE_DETAILS: "Certificate Details",
  },
  NC_FORMS: ["OF", "UF", "GF", "M11"],
  SSA_FORM_SHORT_CODE: {
    CONTRACT_REVIEW: "CR1",
    AUTH_LETTER: "3R",
    DR_CHECKLIST: "DRF",
    IHM_PRACTICAL_DEMO: "9U",
    IHM_PRELIMINARY_DATA: "ZZ",
    MS_REPORT: "MSR",
    QS_REPORT: "QSR",
  },

  AUDITOR_OUTPORT_FINDING_SUB_TYPE: [
    { id: 1, name: "Lead Auditor wise Analysis Reports" },
    { id: 2, name: "Outport wise Analysis Reports" },
    { id: 3, name: "Reviewer wise Analysis Reports" },
  ],
  SI_FORM_TYPE_CODES: [
    { ship_type_id: 1, code: "BA" },
    { ship_type_id: 2, code: "BG" },
    { ship_type_id: 12, code: "TU" },
    { ship_type_id: 13, code: "OF" },
  ],
  ISM_DOC_DR_CHECKLIST: "OT",
  DEFAULT_SCHEDULE_TIME: 60,
  REAMRKS_DISABLE_FORMS: ["T5", "P3", "P9", "OZ", "M6", "PA", "OO", "OP", "OY", "10X", "9L", "UN"],
  INVALIDATION_CERT_SHORT_CODE: ["IM", "IP", "IS"],
  IA_CERT_ISSUED_PLACE_FOR_FT: "MUMBAI",
  ERS_CERT_ISSUED_PLACE_FOR_FT: "MUMBAI",
  RPS_DOCUMENTS_CHECKLIST: {
    AUTHORIZATION_LETTER: "Authorization Letter Documents",
  },
  RPS_FORM_SHORT_CODE: {
    CONTRACT_REVIEW: "CR1",
    AUTH_LETTER: "PA",
    AMR: "10V",
    REPORT_REVIEW_REMARKS: "OP",
  },
  VIEW_REPORTS_SHORT_CODE: {
    SURVEY_REQUEST_DOC: "survey_request_doc",
    DURING_SURVEY_DOC: "during_survey_doc",
    NC_SECTION: "nc_section",
    NC_DOC: "Document",
    NC_MODULE: "NCDocuments",
  },
  RPS_ALLOWED_MARK_LAST_DAY_OF_AUDIT: 3,
  DOCUMENTS_CHECKLIST: {
    DGS_DELIVERABLES: "Report Authorisation",
    CONTRACT_REVIEW: "Contract Review",
    SUPPORTING_DOCS: "Supporting Document",
    MANUAL: "Manual",
  },
  SURVEY_CATEGORY: {
    SMC: "SMC",
    SMV: "SMV",
  },
  CERTIFICATE_PREFIX: "Digital",
  BSA_OTHER_DOC_IDS: [1, 13],
  ALLOWED_SURVEY_TYPE: ["ISM", "MLC", "ISPS"],
  INTERIM_DR_LOCATION: "Document Review - Survey Station",
  DOC_CHECKLIST_FORM_TYPE_CODE: "OT",
  DOC_CHECKLIST_NAME: "Document Review Checklist",
  PORT_HEAD_OFFICE_DETAILS: {
    PORT_NAME: "HEAD OFFICE",
    PORT_CODE: "H.O",
    PORT_ID: 21,
  },
  CLIENT_LOGIN_AUDIT_SURVEY_TYPE: {
    TA: "type_approval",
    WA: "work_approval",
    BS: "bs",
  },
  TRAINING_IDENTIFIED: {
    LOCALLY: "Locally at survey station",
    DEPARTMENT: "Training Department",
    NOT_APPLICABLE: "Not applicable",
  },
  MONITORED_LOCALLY_BY: {
    SURVEY_STATION: "Survey station concerned",
    ARRANGED_FROM_HO: "Arranged from H.O",
    REGIONAL_OFFICE: "Regional Office",
  },
  BSA_MIS_SUPPLIERS: [
    { id: "All", supplier: "Select All" },
    { id: 1, supplier: "Active" },
    { id: 0, supplier: "Inactive" },
  ],
  BSA_MIS_IS_SATISFACTORY: [
    { id: "All", supplier: "Select All" },
    { id: "satisfactory", supplier: "Satisfactory " },
    { id: "not_satisfactory", supplier: "Not Satisfactory " },
  ],
  COMMON_REPORT: {
    REPORT_REVIEW_REMARKS: "OP",
  },
  SRFA_SUVREY_CATEGORY_ID: 3083,
  RO: {
    LABEL: "RO",
    VALUE: "ro",
  },
  Flag_: {
    LABEL: "Flag",
    VALUE: "flag",
  },
  SRFA_INITIAL_VALUES: {
    WORKING_LANGUAGE: "English",
  },
  CERTIFICATE_STATUS: {
    ACTIVE: "Active",
  },
  REMARKS_TYPE: {
    ERROR: "Error",
  },
  PS_FORM_SHORT_CODE: {
    ESP: "ER",
    EHS: "S6",
    TM_PT: "T7",
    FORM_SELECTION: "EX",
    FE2: "F2",
    FE5: "F5",
    FE4: "F4",
    FE7: "F7",
    FE8: "F8",
    PERFORMANCE_OF_SERVICE: "KR",
    IMDG_FORMS: ["HW", "DM"],
  },

  WC_DOCUMENTS_CHECKLIST: {
    CONTRACT_REVIEW: "CONTRACT_REVIEW",
  },

  WC_WELDING_POSITION: [
    { id: 1, wc_description: "Downhand (D)", wc_val: "D", wc_category: "WP" },
    { id: 3, wc_description: "Horizontal-vertical (H)", wc_val: "H", wc_category: "WP" },
    { id: 3, wc_description: "Vertical upwards (Vu)", wc_val: "VU", wc_category: "WP" },
    { id: 4, wc_description: "Vertical downwards (Vd)", wc_val: "VD", wc_category: "WP" },
    { id: 5, wc_description: "Overhead (O)", wc_val: "O", wc_category: "WP" },
    { id: 13, wc_description: "All Positions", wc_val: "AP", wc_category: "WP" },
    { id: 14, wc_description: "All positions except Vertical Downwards", wc_val: "AEVD", wc_category: "WP" },
    { id: 15, wc_description: "Forward (F)", wc_val: "F", wc_category: "WP" },
    { id: 16, wc_description: "F,H,V,U,O", wc_val: "F,H,V,U,O", wc_category: "WP" },
    { id: 17, wc_description: "All positions including Vertical Downward", wc_val: "AIVD", wc_category: "WP" },
    { id: 18, wc_description: "All Positions except Vertical Downward", wc_val: "APEVD", wc_category: "WP" },
  ],
  WC_CURRENT_TYPE: [
    { id: 6, wc_description: "AC", wc_val: "AC", wc_category: "CT" },
    { id: 7, wc_description: "DCEP", wc_val: "DCEP", wc_category: "CT" },
    { id: 8, wc_description: "DCEN", wc_val: "DCEN", wc_category: "CT" },
    { id: 9, wc_description: "AC/DC (+/- ve)", wc_val: "AC/DC (+/- ve)", wc_category: "CT" },
    { id: 10, wc_description: "DC (- ve)", wc_val: "DC (- ve)", wc_category: "CT" },
    { id: 11, wc_description: "DC (+/- ve ) ", wc_val: "DC (+/- ve ) ", wc_category: "CT" },
    { id: 12, wc_description: "DC (+ ve)", wc_val: "DC (+ ve)", wc_category: "CT" },
    { id: 17, wc_description: "AC/DCEP", wc_val: "AC/DCEP", wc_category: "CT" },
    { id: 18, wc_description: "AC (- ve)", wc_val: "AC (- ve)", wc_category: "CT" },
    { id: 19, wc_description: "AC (+ ve)", wc_val: "AC (+ ve)", wc_category: "CT" },
    { id: 20, wc_description: "AC (+/- ve ) ", wc_val: "AC (+/- ve ) ", wc_category: "CT" },
    { id: 21, wc_description: "AC/DC (+ ve)", wc_val: "AC/DC (+ ve)", wc_category: "CT" },
    { id: 22, wc_description: "AC/DC (- ve)", wc_val: "AC/DC (- ve)", wc_category: "CT" },
  ],
  ERS_FROM_SHORT_CODE: {
    INTERIM_CERT: "IC",
    FULL_TERM_CERT: "FC",
    DR_CHECKLIST: "DRF",
    SERVICE_REQUEST: "CR",
  },
  PS_CLASS_SURVEYS: {
    GELAIDUP_ID: 287,
    AELAIDUP_ID: 74,
    GELAIDUPRA_ID: 75,
  },
  SRFA_SCOPE: {
    SURVEILLANCE: "Surveillance",
    RENEWAL: "Renewal",
    ADDITIONAL: "Additional",
    INITIAL: "Initial",
  },
  ALL_SURVEY_STATIONS: "all_survey_stations",
  ASSIGNED_SURVEY_STATIONS: "assigned_survey_stations",
  SRFA_AUDIT_ROLES: {
    VALUE: {
      TL: "TL",
      TE: "TE",
      TA: "TA",
      OB: "Observer",
      MONITOR: "Monitor",
    },
    LABEL: {
      TL: "Team Leader",
      TE: "Technical Expert",
      TA: "Trainee Auditor",
      OB: "Observer",
      MONITOR: "Monitor",
    },
  },

  APPLICABLE: {
    LABEL: "Applicable",
    VALUE: "applicable",
  },
  NOT_APPLICABLE: {
    LABEL: "Not Applicable",
    VALUE: "not_applicable",
  },
  FE_MODE_OF_CONSTRUCTION: {
    BLOCK: { LABEL: "Block", VALUE: "block" },
    PANEL: { LABEL: "Panel", VALUE: "panel" },
    COMBINATION: { LABEL: "Combination", VALUE: "combination" },
  },
  FE_TEST_TYPE: {
    AIR_PRESSURE_TETS: { LABEL: "Air Pressure Test (API)", VALUE: "air_pressure_test" },
    HYDROSTATIC_TEST: { LABEL: "Hydrostatic Test (HPT)", VALUE: "hydrostatic_test" },
    HOSE_TEST: { LABEL: "Hose Test", VALUE: "hose_test" },
    VACUUM_TEST: { LABEL: "Vacuum Test", VALUE: "vacuum_test" },
    HYDRO_PNEUMATIC_TEST: { LABEL: "Hydro-pneumatic Test", VALUE: "hydro_pneumatic_test" },
  },
  ESP_SHIP_TYPES: {
    OIL_TANKER: "OIL TANKER",
    BULK_CARRIER: "BULK CARRIER",
    CHEMICAL_TANKER: "OIL/CHEMICAL TANKER",
    DOUBLE_HULL_OIL_TANKER: "DOUBLE HULL OIL TANKER",
    DOUBLE_SKIN_BULK_CARRIER: "DOUBLE SKIN BULK CARRIER",
  },
  FE_TANK_SPACES_LOCATIONS: {
    PORT: { LABEL: "Port", VALUE: "port" },
    STARBOARD: { LABEL: "Startboard", VALUE: "startboard" },
    CENTER: { LABEL: "Center", VALUE: "center" },
  },
  SRFA_FORM_SHORT_CODE: {
    DR_CHECKLIST: "SR",
  },
  MCA_REPORT_TYPES: {
    NEW_CONSTRUCTION: "New Construction",
    EXISTING_SHIPS: "Existing Ships",
    EX_STOCKS: "Ex-Stock",
    MULTIPLE_SHIPS: "Multiple Ships",
    NAVY_COAST_GUARD_VESSELS: "Navy/Coast Guard Vessels",
  },
};

export default constants;
